<i18n>
{
  "en": {
    "call-history-title": "Call History",
    "call-history-subtitle": "The history of calls made by all agents within your LINE WORKS group.",
    "loading": "Loading…",
    "no-call-history": "No calls made yet",
    "call-count": "no calls | 1 call | {0} calls",
    "no-calls": "No call records found",
    "not-answered": "call was not answered",
    "m'm's's'": "m'm's's'"
  },
  "ja": {
    "call-history-title": "通話履歴",
    "call-history-subtitle": "LINE WORKSグループ内のスタッフ全員の通話履歴が表示されます。",
    "loading": "読込中",
    "no-call-history": "まだ通話データはありません",
    "call-count": "{0} コール",
    "no-calls": "通話データが見つかりません",
    "not-answered": "未応対コール",
    "m'm's's'": "m分s秒"
  }
}
</i18n>
<template>
  <v-card elevation="4">
    <v-card-title>{{ $t('call-history-title') }}</v-card-title>
    <v-card-subtitle>{{ $t('call-history-subtitle') }}</v-card-subtitle>
    <v-card-text>
      <v-expansion-panels hover v-model="panel">
        <v-expansion-panel v-if="overviewLoading" disabled>
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-progress-circular indeterminate size="16" width="2" style="margin-right: .5em"></v-progress-circular>
              {{ $t('loading') }}
            </v-row>
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel v-else-if="!hasCalls" disabled>
          <v-expansion-panel-header>
            {{ $t('no-call-history') }}
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel v-else v-for="(month, i) in months" :key="i">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col :class="[open && 'text-h6']">
                  {{ month.month.setLocale($i18n.locale).toLocaleString({ year: 'numeric', month: 'long' }) }}
                </v-col>
                <v-col class="text--secondary text-right">
                  <v-btn v-if="open && debug"
                         tile
                         small
                         class="mr-1"
                         color="warning" @click.stop="sendChatNotifications(month.month)">
                    <v-icon left>mdi-bug</v-icon> Test chat notifications
                  </v-btn>
                  <span v-if="open && month.calls">
                    <v-chip small label class="mr-1" color="primary">
                      <v-icon left small>mdi-clock-outline</v-icon>
                      {{ totalDuration(month.calls).toFormat($t("m'm's's'")) }}
                    </v-chip>
                    <v-chip v-if="open && month.calls"
                            small
                            label
                            class="mr-1"
                            :color="totalCost(month.calls) > 0 ? 'primary' : null">
                      {{ $n(totalCost(month.calls), 'currency') }}
                    </v-chip>
                  </span>
                  {{ $tc('call-count', month.count, [month.count]) }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader type="list-item-two-line@2" :loading="callsLoading">
              <v-list>
                <v-list-item v-if="selectedCalls.length === 0">
                  <v-list-item-content>
                    <v-alert outlined type="info">{{ $t('no-calls') }}</v-alert>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else v-for="i in paginatedCalls" :key="i.call.id" two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters>
                        <v-col v-if="i.call.operator">{{ i.call.operator }}</v-col>
                        <v-col v-else class="text--secondary">{{ $t('not-answered') }}</v-col>
                        <v-col class="text-right">
                          <v-chip v-if="i.call.duration"
                                  small
                                  label
                                  class="mr-1"
                                  :outlined="isWithinGracePeriod(i)"
                                  :color="isWithinGracePeriod(i) ? null : 'primary'">
                            <v-icon left small>mdi-clock-outline</v-icon>
                            {{ i.call.duration.toFormat($t("m'm's's'")) }}
                          </v-chip>
                          <v-chip v-if="i.price"
                                  small
                                  label
                                  class="ml-1"
                                  :outlined="isFree(i.price)"
                                  :color="!isFree(i.price) ? 'primary' : isWithinGracePeriod(i) ? null : 'success'">
                            {{ $n(priceToNumeric(i.price), 'currency') }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row no-gutters>
                        <v-col>
                          {{ i.call.timestamp.setLocale($i18n.locale).toLocaleString(timeFormat) }}
                        </v-col>
                        <v-col class="text-right font-weight-thin">{{ i.call.id }}</v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-pagination v-model="page" :length="pages" v-if="pages > 1"></v-pagination>
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime, Duration } from 'luxon';

export default {
  name: 'CallHistory',
  data: () => ({
    debug: process.env.VUE_APP_DEBUG_FEATURES,
    months: undefined,
    panel: undefined,
    page: 1,
    limit: 10,
    timeFormat: { month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }
  }),
  async mounted() {
    /** @type {Response} */
    const response = await this.$api.fetch('/billing/calls');
    const result = await response.json();
    // noinspection JSCheckFunctionSignatures
    this.months = result.data
        .map(([ts, count]) => ({
          month: DateTime.fromSeconds(ts, { zone: 'Asia/Tokyo' }),
          count,
          calls: undefined
        }))
        .sort((a, b) => b.month - a.month);
  },
  computed: {
    overviewLoading() {
      return typeof this.months === 'undefined';
    },
    hasCalls() {
      return this.months && this.months.length > 0;
    },
    callsLoading() {
      return typeof this.panel !== 'undefined' && typeof this.months[this.panel].calls === 'undefined';
    },
    selectedCalls() {
      if (typeof this.panel === 'undefined' || typeof this.months[this.panel] === 'undefined' || !this.months[this.panel].calls) {
        return [];
      }
      return this.months[this.panel].calls || [];
    },
    paginatedCalls() {
      return this.selectedCalls.slice((this.page - 1) * this.limit, this.page * this.limit)
    },
    totalCalls() {
      return this.selectedCalls.length;
    },
    pages() {
      return Math.ceil(this.totalCalls / this.limit);
    }
  },
  watch: {
    async panel(panel) {
      this.page = 1;

      if (typeof panel === 'undefined') {
        return;
      }

      const month = this.months[panel];

      if (typeof month.calls !== 'undefined') {
        return;
      }

      /** @type {Response} */
      const response = await this.$api.fetch(`/billing/calls/${month.month.toFormat('yyyy-MM')}`);

      if (!response.ok || response.status === 204) {
        return this.months[panel].calls = null;
      }

      const result = await response.json();
      // noinspection JSCheckFunctionSignatures
      this.months[panel].calls = result.data.map(i => ({
        ...i,
        call: {
          ...i.call,
          timestamp: DateTime.fromSeconds(i.call.timestamp, { zone: 'Asia/Tokyo' }),
          duration: i.call.duration && Duration.fromMillis(i.call.duration * 1000),
        }
      }));
    }
  },
  methods: {
    isFree(price) {
      return this.priceToNumeric(price) === 0;
    },
    priceToNumeric(price) {
      return Number(price.split(' ')[1]);
    },
    isWithinGracePeriod(item) {
      return this.isFree(item.price) && !item.call.duration || item.call.duration.as('seconds') <= 120;
    },
    totalDuration(calls) {
      // noinspection JSCheckFunctionSignatures
      return calls.reduce((t, i) => t.plus(i.call.duration || 0), Duration.fromMillis(0));
    },
    totalCost(calls) {
      return calls.reduce((t, i) => t + (i.price ? this.priceToNumeric(i.price) : 0), 0);
    },
    async sendChatNotifications(month) {
      const resp = await this.$api.fetch(`/payments/debug/notifications?month=${month.toFormat('yyyy-M')}`, { method: 'POST' });

      if (resp.ok) {
        alert('Sent');
      } else {
        alert(await resp.text());
      }
    }
  }
}
</script>

<style scoped>

</style>